import {regex, required, useNotify, useRecordContext, useRefresh} from "react-admin";
import {Box, Button, Card, CardContent, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ButtonPostRequest} from "../components/ButtonPost";
import {useState} from "react";
import {copyToClipboard, notify_s, notify_w, SERVER} from "../utils";

export const ClientShow = () => {
    const [sum, setSum] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [requisites, setRequisites] = useState('')
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    if (!record) return null;

    function onSuccess() {
        setSum('')
        refresh()
    }

    function _setSum(event) {
        if (event.target.value === "-") {
            setSum(event.target.value)
            return
        }
        const v = parseInt(event.target.value)
        if (isNaN(v))
            setSum('0')
        else
            setSum(v.toString())

    }

    function copy(text) {
        copyToClipboard(text)
        notify(`Скопировано`, notify_s);
    }

    function showNotify(e) {

        if (e) {
            if (e["status"] === 'ok') {
                notify("Сохранено", {type: 'success'});
                onSuccess()
            }

        } else {
            notify(`Ошибка`, {type: 'error'});
        }
    }

    function editBalance() {
        fetch(`${SERVER}/admin/add_transaction/${record.id}`, {
            method: 'POST',
            body: JSON.stringify({sum: sum})
        })
            .then(response => response.json())
            .then(result => (showNotify(result)))


    }

    return (
        <Card sx={{width: 'auto'}}>
            <CardContent>
                <span>API key: <span
                    className="blue"
                    onClick={() => copy(record.secret_key)}
                >{record.secret_key}</span></span>

                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="right"
                    spacing={2}
                >
                    <TextField label="Сумма" variant="outlined" value={sum}
                               onChange={event => _setSum(event)}
                               validate={[required(), regex(/^-?\d+$/)]}
                    />

                    <Button variant="outlined" onClick={editBalance} color="warning"> Изменить </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}


