import {io} from 'socket.io-client';
import {SOCKET_PATH} from "./utils";

export const socket = io(SOCKET_PATH, {
    path: "/backend/socket.io/",
    auth: {
        access: localStorage.getItem('access'),
        latest_session_id: localStorage.getItem('latest_session_id'),
        order_id: new URLSearchParams(window.location.search).get('id')
    },
    transports: ['websocket'],
    // transports: ['websocket', 'polling', 'flashsocket'],
    withCredentials: true,
    // autoConnect: false
});

const timeout = 1000

export function emit({
                         event, data,
                         onSuccess = () => {
                         },
                         onError = () => {
                         },
                         onDone = () => {
                         },
                         saveMessage = true,
                         show_loader = false,
                         show_error = true
                     } = {}) {

    // if (show_loader)
    //     topLoaderData.show()

    socket.timeout(timeout).emit(event, data, (err, response) => {
        if (err) {
            console.log(err)
            onError()
            // if(show_error)
            //     ShowTimeOutError(event)
        } else if (response || (typeof (response) === 'number' || response instanceof Number)) {
            console.log(response)
            // if (saveMessage) {
            //     pushData.type.set("success")
            //     pushData.show(langPack.saved[localData.lang], 500)
            // }
            onSuccess(response)
        } else {
            console.log(response)
            // if(show_error)
            //     ShowError()
        }
        onDone()
        // if (show_loader)
        //     topLoaderData.hide()
    })
}
