import {
    BooleanField,
    CreateButton,
    DatagridConfigurable,
    DeleteButton,
    EditButton, FilterList, FilterListItem, FilterLiveSearch,
    List,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import {getColor, make_id, moneyFormat} from "../utils";
import {OrderShow} from "../order/OrderShow";
import {ClientShow} from "./ClientShow";
import {Card, CardContent} from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <CreateButton/>
    </TopToolbar>
);

function BalanceField({source}) {
    const record = useRecordContext();
    const recordSource = make_id();
    console.log("record.withdrawals", record)

    let balance = 0
    const orderList = record[source]
    const withdrawals = record.withdrawals
    let withdrawalSum = 0
    let transactionSum = 0
    for (const i in withdrawals) {
        if (withdrawals[i].status !== 2)
            withdrawalSum += withdrawals[i].amount
    }
    for (const i in orderList) {
        if (orderList[i].status === 5) {
            balance += orderList[i].client_income
        }
    }

    for (const i in record.transactions) {
        if (record.transactions[i].is_input) {
            transactionSum += record.transactions[i].amount
        }
        else{
            transactionSum -= record.transactions[i].amount
        }
    }

    balance -= withdrawalSum
    balance += transactionSum
    record[recordSource] = moneyFormat(balance)
    return (<TextField source={recordSource} style={{color: getColor('green')}}/>)

}

function HoldField() {
    const record = useRecordContext();
    const recordSource = make_id();
    const withdrawals = record.withdrawals
    let holdSum = 0
    for (const i in withdrawals) {
        if (withdrawals[i].status === 0)
            holdSum += withdrawals[i].amount
    }
    record[recordSource] = moneyFormat(holdSum)
    return (<TextField source={recordSource} style={{color: getColor('orange')}}/>)
}

export const ClientList = () => (
    <List
        actions={<ListActions/>}
        sort={{field: 'id', order: 'DESC'}}
        aside={<FilterSidebar/>}

    >
        <DatagridConfigurable
            rowClick="expand"
            expand={<ClientShow/>}
            expandSingle
        >
            <TextField source="id" sortable={false}/>
            <TextField source="project_name" label="Название" sortable={false}/>
            <TextField source="project_link" label="Ссылка" sortable={false}/>
            <TextField source="telegram_id" label="Telegram ID" sortable={false}/>
            <BooleanField source="commission_on_buyer" label="Комиссия на покупателе" sortable={false}/>
            {/*<TextField source="secret_key" label="API key" sortable={false}/>*/}
            <BooleanField source="is_active" label="Активен" sortable={false}/>
            <BooleanField source="is_p2p_api" label="P2p API" sortable={false}/>
            <TextField source="tariff.tariff_name" label="Тариф" sortable={false}/>
            <BalanceField source="orders" label="Баланс"/>
            <HoldField label="Холд"/>
            <EditButton/>
            <DeleteButton/>
        </DatagridConfigurable>
    </List>
)


export const FilterSidebar = (() => {

    return (
        <Card sx={{order: -1, mr: 2, mt: 9, width: 220}}>
            <CardContent>
                <FilterLiveSearch source="q" label="Поиск"  name="search"/>

                <FilterList label="По статусу" icon={<SyncProblemIcon/>}>
                    <FilterListItem label="Активен" value={{is_active: true}}/>
                    <FilterListItem label="Заблокирован" value={{is_active: false}}/>
                </FilterList>

                <FilterList label="По комиссии" icon={<SyncProblemIcon/>}>
                    <FilterListItem label="На покупателе" value={{commission_on_buyer: true}}/>
                    <FilterListItem label="На продавце" value={{commission_on_buyer: false}}/>
                </FilterList>

            </CardContent>

        </Card>
    )
})
