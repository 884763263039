import {Edit, regex, required, SaveButton, SimpleForm, TextInput, Toolbar} from "react-admin";
import {Box, Card, Grid, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {ButtonPostRequest} from "./components/ButtonPost";
import {emit, socket} from "./sockets";
import {moneyFormat} from "./utils";
import {TogglerPostRequest} from "./components/TogglerPostRequest";


function Settings() {
    const [rateFactor, setRateFactor] = useState("")
    const [contact, setContact] = useState("")
    const [balance, setBalance] = useState("")
    const [p2pMode, setP2pMode] = useState("")
    const [serviceMode, setServiceMode] = useState("")
    const [withdrawal, setWithdrawal] = useState("")
    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")


    useEffect(() => {
        function setData(e) {
            console.log(e)
            setRateFactor(e.factor)
            setContact(e.contact)
            setBalance(e.owner_balance)
            setP2pMode(e.p2p_mode)
            setServiceMode(e.service_mode)
        }

        function getOrderData() {
            console.log("getOrderData")
            emit({
                event: 'get_settings', data: {}, onSuccess: setData, onError: () => setTimeout(getOrderData, 1000)
            })
        }

        getOrderData()

    }, [])

    function updateBalance(){
        console.log("updateBalance")
        const _balance = parseFloat(balance)
        const _withdrawal = parseFloat(withdrawal)
        const newBalance = _balance - _withdrawal
        setBalance(newBalance.toString())
        setWithdrawal("")
    }
    return (
        <Card sx={{width: 'auto', mt: 5}}>
            <Box m={4}>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <h2>Настройки</h2>
                        <TextField label="% к курсу валют" variant="outlined" value={rateFactor}
                                   validate={[required(), regex(/^\d+$/)]}
                                   onChange={event => setRateFactor(event.target.value)}/>
                        <br/>
                        <TextField label="Контакты" variant="outlined" value={contact}
                                   onChange={event => setContact(event.target.value)}/>
                        <br/>
                        <br/>
                        <ButtonPostRequest
                            endpoint="settings"
                            target="set"
                            data={{factor: rateFactor, contact: contact}}
                            label="Сохранить"
                            okMessage="Сохранено"
                            color="info"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <h2>Пароль администратора</h2>
                        <TextField label="Текущий пароль" variant="outlined" value={currentPass}
                                   onChange={event => setCurrentPass(event.target.value)}/>
                        <br/>
                        <TextField label="Новый пароль" variant="outlined" value={newPass}
                                   onChange={event => setNewPass(event.target.value)}/>
                        <br/>
                        <br/>
                        <ButtonPostRequest
                            endpoint="password"
                            target="change"
                            data={{current_pass: currentPass, new_pass: newPass}}
                            label="Сохранить"
                            okMessage="Пароль изменен"
                            color="info"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <h2>Баланс платежки</h2>
                        <h3>{moneyFormat(balance)}</h3>

                        <TextField label="Списать с баланса" variant="outlined" value={withdrawal}
                                   validate={[required(), regex(/^\d+$/)]}
                                   onChange={event => setWithdrawal(event.target.value)}/>
                        <br/>
                        <br/>
                        <ButtonPostRequest
                            endpoint="owner_withdrawal"
                            target="create"
                            data={{amount: withdrawal}}
                            label="Вывести"
                            okMessage="Списано"
                            color="success"
                            variant="outlined"
                            onSuccess={updateBalance}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <h2>Прочее</h2>
                        <TogglerPostRequest
                            checked={p2pMode}
                            setChecked={setP2pMode}
                            data={{}}
                            target="set"
                            endpoint="p2p_mode"
                            okMessage="Сохранено"
                            label="Автовыдача"
                            labelPlacement="end"
                        />
                        <br/>
                        <TogglerPostRequest
                            checked={serviceMode}
                            setChecked={setServiceMode}
                            data={{}}
                            target="set"
                            endpoint="service_mode"
                            okMessage="Сохранено"
                            label="Тех обслуживание"
                            labelPlacement="end"
                        />
                    </Grid>

                </Grid>

            </Box>
        </Card>
    )
}

export default Settings
