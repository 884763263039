import loader from "../image/loader.svg";
import GppBadIcon from '@mui/icons-material/GppBad';
import {useEffect} from "react";
import '../css/style.css';
import '../css/cashnotdone.css';
import logo from "../img/logo.png"
import logo_cashnotdone from "../img/cashnotdone.png"

function PaymentDeclined({orderData}) {
    useEffect(() => {
        if (orderData.url) {
            setTimeout(() => {
                window.location.replace(orderData.url)
            }, 3000)
        }
    })
    return (
        <div className="body-container">
            <div className="main2 main3 main4">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46" alt=""/>
                                <p>APAYS</p>
                            </div>
                        </div>
                        <p className="check-pay">Проверяем оплату</p>
                        <div className="notfoundimg">
                            <img src={logo_cashnotdone} alt=""/>
                            <p className="notfoundtxt">Платеж отклонен!</p>
                        </div>
                        {
                            !orderData.url
                                ? (
                                    <>
                                        <div className="contacts-test">
                                            <p>Если у вас есть вопросы, напишите нам:</p>
                                        </div>
                                        <div className="contacts">
                                            <p className="fs-16 blue"
                                               onClick={()=>window.location.replace(`https://t.me/${orderData.contact}`)}
                                            >{orderData.contact}</p>
                                        </div>
                                    </>

                                )
                                : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

// function PaymentDeclined({orderData}) {
//     useEffect(()=>{
//         if(orderData.url){
//             setTimeout(()=>{
//                 window.location.replace(orderData.url)
//             }, 3000)
//         }
//     })
//     return (
//         <div className="container">
//             <div className="box">
//                 <div className="center">
//                     <b className="fs-20">Платеж отклонен</b>
//                 </div>
//                 <div className="loader-box">
//                     <GppBadIcon color="error" sx={{ fontSize: 140 }}/>
//                 </div>
//                 <div className="center">
//                     <p>Если у вас есть вопросы, напишите нам:</p>
//                     <span className="fs-16 blue">{orderData.contact}</span>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default PaymentDeclined
