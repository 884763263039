// import loader from "../image/loader.svg";
import '../css/style.css';
import '../css/page3.css';
import logo from "../img/logo.png"

function WaitCheck({orderData}) {
    return (
        <div className="body-container">
            <div className="main4">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="logo-cont">
                            <img src={logo} width="46" height="46"/>
                            <p>APAYS</p>
                        </div>
                        <div className="check-pay">
                            <p className="check-pay-text">Проверяем оплату</p>
                            <div className="load-block">
                                <div className="loader"></div>
                            </div>
                        </div>

                        <p className="itcan">Это может занять от 1 до 15 минут</p>
                        <p className="cont">Если у вас есть вопросы, напишите нам:</p>
                        <p className="cont fs-16 blue"
                           onClick={()=>window.location.replace(`https://t.me/${orderData.contact}`)}
                        >{orderData.contact}</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

// function WaitCheck({orderData}) {
//     return (
//         <div className="container">
//             <div className="box">
//                 <div className="center">
//                     <b className="fs-20">Проверяем оплату</b>
//                 </div>
//                 <div className="loader-box">
//                     <img src={loader} className="rotate" alt="please wait"/>
//                 </div>
//                 <p>Если у вас есть вопросы, напишите нам: {orderData.contact}</p>
//             </div>
//         </div>
//     )
// }

export default WaitCheck
